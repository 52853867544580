<template>
	<div style="border: 1px solid #09568d;">
		<form v-if="!isLoading" @submit.prevent="onSubmit" novalidate>
			<nav class="navbar sticky-top navbar-light bg-dark">
				<div class="container-fluid">
					<span class="navbar-brand mb-0 h6 text-light" style="">{{ vv.question_content.$model.length }} question(s) in total</span>
					<div class="">
						<button type="submit" class="btn btn-primary btn-sm" :disabled="isProcessing">
							<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							<span v-if="isProcessing" style="padding-left: 15px !important;">Updating......</span>
							<span v-if="!isProcessing">Update Question</span>
						</button>
					</div>
				</div>
			</nav>
			<div class="frame bg-white" style="padding: 2rem 1rem;">
				<div class="card">
					<div class="card-body">
						<div class="row g-3">
							<div class="col-6">
								<label class="form-label">Subject name</label>
								<select class="form-select" v-model="vv.subject_id.$model">
									<option :value="initialValue">Select subject</option>
									<option v-for="(item, i) in subjectList" :key="i" :value="item.subject_id">{{ item.subject_title }}</option>
								</select>
								<span class="error">{{ vv?.subject_id?.$errors[0]?.$message }}</span>
							</div>
							<div class="col-6">
								<label class="form-label">Question Year</label>
								<select class="form-select" v-model="vv.year_id.$model">
									<option :value="initialValue">All Years</option>
									<option v-for="(item, i) in yearList" :key="i" :value="item.year_id">{{ item.year_name }}</option>
								</select>
								<span class="error">{{ vv?.year_id?.$errors[0]?.$message }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-2 card" style="margin-top: 20px !important;" v-for="(item, i) in vv.question_content.$model" :key="i">
					<div class="card-header">
						Question {{ i + 1 }}
					</div>
					<div class="card-body">
						<div class="row g-3">
							<div class="col-12 question">
								<label for="">Question Instruction</label>
								<input type="text" class="form-control" v-model="item.instruction">
								<span class="error">{{ item?.instruction?.$errors?.$message }}</span>
							</div>
							<div class="col-12 question">
								<label for="">Question</label>
								<ckeditor :editor="editor" v-model="item.question" :config="editorConfig"></ckeditor>
								<span class="error">{{ item?.question?.$errors?.$message }}</span>
							</div>
							
							<div class="col-6 options">
								<label for="">Correction </label>
								<ckeditor :editor="editor" v-model="item.correction" :config="correctionConfig"></ckeditor>
							</div>
							<div class="col-6">
								<div>
									<label>Question Image</label>
								</div>
								<img class="image" id="previewImage" :src="setImage(item.question_image)" alt="">
								<label class="custom-file-upload">
									<input type="file" @change="handleFileChange" :data-id="i" id="profile-image-input" accept="image/*"/>
									Add Question Related Image
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
		<div class="card" v-if="isLoading && !showErrorPageAlert">
			<div class="card-body loader" v-if="isLoading">
				<div class="spinner-border"></div>
				<h6 style="margin-top: 15px; font-size: 17px;">{{ loadingMessage }}</h6>
			</div>
		</div>
		<div class="alert alert-danger" v-if="isLoading && showErrorPageAlert" style="margin-bottom: 0px !important;">
			<h4 style="font-size: 17px !important; font-weight: bold;">{{ errorMessage }}</h4>
			<button class="btn btn-danger" @click="loadTopic" style="margin-top: 7px;">Reload Content</button>
		</div>
	</div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Mathematics from 'ckeditor5-math/src/math';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import { projectService } from '@/services/project.service'
import { waecFileMgt } from '@/services/waecFileMgt.service'

export default {
	name: 'CreatePastQuestion',
	props: {
		questionId: Number,
		yearList: [Array, Object],
		subjectList: [Array, Object],
		subjectId: Number
	},
	setup(props) {
		const fform = reactive({
			year_id: null,
			subject_id: props.subjectId,
			question_content: [
				{
					id: null,
					instruction: '',
					question: '',
					question_image: '',
					correction: ''
				}
			]
		})
		const rules = computed(() => (
			{
				year_id: { required },
				subject_id: { required },
				question_content: {}
			}
		))
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			folder_path: '',
			isLoading: true,
			isProcessing: false,
			showErrorPageAlert: false,
			errorMessage: '',
			loadingMessage: 'Loading Theory Past Question',
			topicList: [],
			initialValue: null,
			question: [],
			questionImageList: [],
			editor: ClassicEditor,
			editorConfig: {
				plugins: [
				EssentialsPlugin,
				BoldPlugin,
				ItalicPlugin,
				ParagraphPlugin,
				Alignment,
				Mathematics,
				],
				toolbar: {
					items: [
					'bold',
					'italic',
					'undo',
					'redo',
					'alignment',
					'math'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 400,
				allowedContent: true
			},
			correctionConfig: {
				plugins: [
				EssentialsPlugin,
				BoldPlugin,
				ItalicPlugin,
				ParagraphPlugin,
				Mathematics,
				Alignment,
				Base64UploadAdapter,
				Image,
				ImageCaption,
				ImageStyle,
				ImageToolbar,
				ImageUpload,
				ImageResize
				],
				toolbar: {
					items: [
					'bold',
					'italic',
					'undo',
					'redo',
					'math',
					'alignment',
					'uploadImage'
					]
				},
				image: {
					toolbar: [
						'imageStyle:full',
						'imageStyle:side',
						'|',
						'imageTextAlternative'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 400,
				allowedContent: true
			},
			isImageChanged: false,
			isCorrectionImageChanged: false
		};
	},
	mounted () {
		this.folder_path = this.$route.params.folder
		this.loadQuestion()
	},
	methods: {
		async loadQuestion () {
			const postData = {
				folder: this.folder_path,
				test_question_id: this.questionId
			}
			waecFileMgt.fetchSingleTheoryPastQuestion(postData)
				.then((response) => {
					if(parseInt(response.data.message_code) === 200){
						for(const item of response.data.question){
							if(parseInt(item.test_question_id) === parseInt(this.questionId)){
								this.question = item
								this.fform.year_id = this.question.year_id
								this.fform.question_content[0].instruction = this.question.question_instr
								this.fform.question_content[0].id = item.test_question_id
								this.fform.question_content[0].question = this.$generalHelper.decodeHtmlEntites(this.question.question)
								this.fform.question_content[0].correction = this.$generalHelper.decodeHtmlEntites(this.question.correction)
								this.fform.question_content[0].question_image = this.question.question_image
							}
						}
						this.isLoading = false
						setTimeout(() => {
							eval('MathJax.Hub.Queue(["Typeset", MathJax.Hub])');
						}, 500)
					}else{
						this.isLoading = true
						this.showErrorPageAlert = true
						this.errorMessage = response.data.message_data
					}
				})
				.catch((error) => {
					console.log(error.message)
					this.$swal({
						text: 'Error Connecting To Server',
						icon: 'error'
					})
				})
		},
		setImage (image) {
			if(image.length > 0){
				const index = image.indexOf('base64')
				if(index !== -1) {
					return image
				}else{
					return `${projectService.apiUrl}/load-project-image/WAEC/test_past_question/${image}`
				}
			}
		},
		async handleFileChange (event) {
			const index = event.target.dataset.id
			const elem = event.target
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const image = await this.$imageHelper.compressImage(elem.files[0])
				this.fform.question_content[index].question_image = image
				this.isImageChanged = true
			}
		},
		computeTotalQuestion () {
			return new Promise((resolve) => {
				const newPastQuestion = []
				this.fform.question_content.map((item) => {
					if(item.question.length > 0) {
						newPastQuestion.push(`(${ this.fform.subject_id })`)
					}
				})
				resolve(newPastQuestion.length)
			})
		},
		async onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;

			const totalQuestions = await this.computeTotalQuestion()
			if(totalQuestions === 0){
				this.$swal({
					icon: 'error',
					text: 'Please enter atleast a question before submission'
				})
				return
			}

			this.$swal({
				text: `Are you sure that all input are entered correctly`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, update',
				allowOutsideClick: false
			}).then(async (response) => {
				if(response.isConfirmed) {
					this.isProcessing = true
					const postData = {
						folder: this.folder_path,
						question_content: this.fform,
						test_question_id: this.questionId,
						old_question_image: this.question?.question_image
						//old_correction_image: this.question?.correction_image
					}
					waecFileMgt.updateTheoryPastQuestion(postData)
						.then((response) => {
							this.isProcessing = false
							if(parseInt(response.data.message_code) === 200){
								this.$swal('Past question updated successsfull')
								this.$emit('closeForm')
							}else{
								this.$swal({
									text: response.data.message,
									icon: 'error'
								})
							}
						})
						.catch((error) => {
							this.isProcessing = false
							console.log(error.message)
							this.$swal({
								text: 'Error Connecting To Server',
								icon: 'error'
							})
						})
				}
			})
		}
	}
};
</script>

<style scoped>
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
input[type="file"] {
	display: none;
}
.custom-file-upload{
	margin-top: 0px;
	display: inline-block;
	padding: 5px 10px;
	cursor: pointer;
	background-color: #09568d !important;
	height: auto;
	color: #ffffff;
	border-radius: 5px;
	font-weight: 400 !important;
}
.image{
	width: 100px;
	height: 100px;
	border-radius: 5px;
	position: relative;
	border: 4px solid #f4f5f8;
	background-color: #f4f5f8;
	margin-right: 20px;
}
.frame{
	height: calc(100vh - 320px);
	overflow-y: scroll;
	padding-bottom: 30px;
}
.card .card-body.loader{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
</style>